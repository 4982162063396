import {maybeInstallMembersArea} from '@wix/members-area-integration-kit'
import type {EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import {
  GROUPS_APP_DEF_ID,
  PAID_PLANS_APP_DEF_ID,
  SCHEDULE_SECTION_ID,
  EVENTS_WIDGET_ID,
} from '@wix/wix-events-commons-statics'
import {BASE_INSTALL_CONFIG, EVENTS_APP_DEF_ID, LIVE_VIDEO_APP_DEF_ID} from './constants'
import {ProgressBar} from './services/progress-bar'
import {WithApproval} from './services/concurrent-editing'
import {TFunction} from './services/translations'

interface HandleAppInstalledParams {
  appDefinitionId: string
  pageInstallFlowEnabled: boolean
  disableMembersInstall: boolean
  adi: boolean
  progressBar: ProgressBar
  withApproval: WithApproval
  forcedPageInstall: boolean
  editorSdk: EditorSDK
  appToken: string
  handleMembersAreaSilentInstallEnabled: boolean
}

export const handleAppInstalled = async ({
  appDefinitionId,
  pageInstallFlowEnabled,
  disableMembersInstall,
  adi,
  progressBar,
  withApproval,
  forcedPageInstall,
  editorSdk,
  appToken,
  handleMembersAreaSilentInstallEnabled,
}: HandleAppInstalledParams) => {
  switch (appDefinitionId) {
    case EVENTS_APP_DEF_ID:
      if (pageInstallFlowEnabled && !adi) {
        try {
          progressBar.open(BASE_INSTALL_CONFIG)
          await Promise.all([
            withApproval(() => newInstallFlow({forcedPageInstall, editorSdk, appToken})),
            disableMembersInstall ? null : withApproval(() => maybeInstallMembersArea()),
          ])
          await progressBar.close()
        } catch (e) {
          console.error(e)
          await progressBar.close()
        }
      } else {
        try {
          progressBar.open(BASE_INSTALL_CONFIG)
          if (!adi && !disableMembersInstall) {
            await withApproval(() => maybeInstallMembersArea())
          }
          if (handleMembersAreaSilentInstallEnabled) {
            await handleMembersAreaSilentInstall(editorSdk, appToken)
          }
          await progressBar.close()
        } catch (e) {
          console.error(e)
          await progressBar.close()
        }
      }
      return
    default:
      return
  }
}

interface NewInstallFlowParams {
  editorSdk: EditorSDK
  appToken: string
  forcedPageInstall: boolean
}

const newInstallFlow = async ({editorSdk, appToken, forcedPageInstall}: NewInstallFlowParams) => {
  const installed = await isEventsInstalled({editorSdk, appToken, forcedPageInstall})

  if (!installed) {
    await editorSdk.pages.add(appToken, {
      title: 'Events',
      shouldAddMenuItem: true,
      shouldNavigateToPage: true,
      definition: {data: {managingAppDefId: EVENTS_APP_DEF_ID}},
    })

    await editorSdk.tpa.add.component(appToken, {
      appDefinitionId: EVENTS_APP_DEF_ID,
      componentType: 'WIDGET' as TPAComponentType.Widget,
      widget: {
        widgetId: 'wix_events',
        shouldNavigate: false,
        allPages: false,
      },
    })
  }
}

interface IsEventsInstalledParams {
  editorSdk: EditorSDK
  appToken: string
  forcedPageInstall: boolean
}

export const isEventsInstalled = ({editorSdk, appToken, forcedPageInstall}: IsEventsInstalledParams) => {
  if (forcedPageInstall) {
    // is platform app installed
    return editorSdk.application.isApplicationInstalled(appToken, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  } else {
    // is widget installed
    return editorSdk.tpa.isApplicationInstalled(appToken, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  }
}

interface AddParams {
  editorSdk: EditorSDK
  appToken: string
}

const addPaidPlans = ({editorSdk, appToken}: AddParams) =>
  editorSdk.document.tpa.add.application(appToken, {appDefinitionId: PAID_PLANS_APP_DEF_ID})

const addLiveVideo = ({editorSdk, appToken}: AddParams) =>
  editorSdk.document.tpa.add.application(appToken, {appDefinitionId: LIVE_VIDEO_APP_DEF_ID})

const addGroups = ({editorSdk, appToken}: AddParams) =>
  editorSdk.document.tpa.add.application(appToken, {appDefinitionId: GROUPS_APP_DEF_ID})

interface HandleMigrateParams {
  payload: any
  withApproval: WithApproval
  editorSdk: EditorSDK
  appToken: string
  t: TFunction
  progressBar: ProgressBar
  forcedPageInstall: boolean
}

export const handleMigrate = ({
  payload,
  withApproval,
  editorSdk,
  appToken,
  t,
  progressBar,
  forcedPageInstall,
}: HandleMigrateParams) => {
  if (payload?.addPaidPlans) {
    return withApproval(() => addPaidPlans({editorSdk, appToken}))
  }

  if (payload?.addSchedulePage) {
    return withApproval(() =>
      installPage({
        editorSDK: editorSdk,
        pageId: SCHEDULE_SECTION_ID,
        token: appToken,
        title: t('pagesPanelEventsScheduleTitle'),
        progressBar,
        forcedPageInstall,
      }),
    )
  }

  if (payload?.addLiveVideo) {
    return withApproval(() => addLiveVideo({editorSdk, appToken}))
  }

  if (payload?.addGroups) {
    return withApproval(() => addGroups({editorSdk, appToken}))
  }

  return
}

interface InstallPageParams {
  editorSDK: EditorSDK
  token: string
  pageId: string
  title?: string
  progressBar: ProgressBar
  forcedPageInstall: boolean
}

export const installPage = async ({
  editorSDK,
  token,
  pageId,
  title,
  progressBar,
  forcedPageInstall,
}: InstallPageParams) => {
  // This is needed because Editor script runs even if Events are uninstalled...
  // Not having this check makes it impossible to uninstall Events

  const eventsInstalled = await isEventsInstalled({editorSdk: editorSDK, appToken: token, forcedPageInstall})

  const pageInstalled = await editorSDK.tpa.isAppSectionInstalled(token, {
    sectionId: pageId,
    appDefinitionId: EVENTS_APP_DEF_ID,
  })

  if (eventsInstalled && !pageInstalled) {
    if (forcedPageInstall) {
      progressBar.open(BASE_INSTALL_CONFIG)
    }
    try {
      return await editorSDK.tpa.add.component(token, {
        appDefinitionId: EVENTS_APP_DEF_ID,
        componentType: 'PAGE' as TPAComponentType.Page,
        page: {
          pageId,
          title,
          isHidden: true,
          shouldNavigate: false,
        },
      })
    } catch (e) {
      console.log('Prevented exception from breaking editor script!', e)
      await progressBar.close()
      return null
    }
  }
}

const handleMembersAreaSilentInstall = async (editorSdk: EditorSDK, appToken: string) => {
  const appData = await editorSdk.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID)
  const components = await editorSdk.tpa.app.getAllCompsByApplicationId('', appData.applicationId)
  const widget = components.find(c => c.widgetId === EVENTS_WIDGET_ID)

  await (widget &&
    editorSdk.document.pages.navigateTo(appToken, {
      pageLink: {
        type: 'PageLink',
        pageId: widget.pageId,
      },
    }))
}
